// I'm leaving these as `require`s rather than `import`s because getting
// everything to work with esm when it was written for <script> and ported to
// cjs is difficult

const $ = require('jquery');

window.$ = $;
window.jQuery = window.$;

const angular = require('angular');
const uiRouter = require('@uirouter/angularjs');
require('./app/store'); // Custom angular bindings to our redux store

require('angular-route'); // Seems unused. $routeProvider is injected in 2 places, but not used in either
require('angular-tooltips'); // Replace this with our own tooltips. Grep for '720kb.tooltips' showed 4 files using this
require('angular-sanitize'); // This seems unused, but more checks needed. Terms: '$sanitize', '$sanitizeProvider', 'linky', 'ngSanitize'
require('angular-animate');

// Replace with moment where used. Not using npm for this because it requires
// further config, and I want to keep this PR as small as possible, just
// changing where the files come from for now.
require('./lib/bower/momentangular-moment.js');

module.exports = {
	angular,
	uiRouter,
};
